import Vue from 'vue';
import './plugins/vuetify';
import './plugins/swiper';
import { sync } from 'vuex-router-sync';
import App from './App.vue';
import createRouter from './router';
import { createStore } from './store';
import './registerServiceWorker';

// console.log(process, '2');
// экспортируем функцию фабрику для создания экземпляров
// нового приложения, маршрутизатора и хранилища
export default function createApp() {
  // Создаём экземпляр маршрутизатора
  const router = createRouter();
  const store = createStore();
  // Синхронизируем чтобы состояние маршрута было доступно как часть хранилища
  sync(store, router);
  const app = new Vue({
    // корневой экземпляр просто рендерит компонент App
    router,
    store,
    render: h => h(App),
  });
  return { app, router, store };
}
