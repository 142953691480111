







import { Vue, Component, Prop } from 'vue-property-decorator';
import Header from '@/components/Header/index.vue';
import Footer from '@/components/Footer/index.vue';
import '@/assets/styles/common.sass';

@Component({
  components: {
    Header,
    Footer,
  },
})
export default class App extends Vue {
  title: string = 'NAURA';
}
