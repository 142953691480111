import createApp from './app';

// process.env.browser = true;
// eslint-disable-next-line
console.log('process.env set', process.env);
const { app, store } = createApp();

// if (window.__INITIAL_STATE__) {
//   // Инициализируем состояние хранилища данными, внедрёнными на сервере
//   store.replaceState(window.__INITIAL_STATE__)
// }
app.$mount('#app');
