import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
})
export default class Header extends Vue {
  public burgerCheckbox = false

  get homeUrl() {
    return this.$route.path === '/' ? '' : '/';
  }
}
