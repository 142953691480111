import Vue from 'vue';
import Router from 'vue-router';

const Home = () => import('./views/Home.vue');

Vue.use(Router);

export default function createRouter() {
  return new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
      {
        path: '/',
        name: 'home',
        component: Home,
      },
      {
        path: '/projects',
        name: 'projectsPage',
        component: () => import(/* webpackChunkName: "about" */ './views/ProjectsPage.vue'),
      },
      {
        path: '/team',
        name: 'team',
        component: () => import(/* webpackChunkName: "about" */ './views/Team.vue'),
      },
    ],
  });
}
